<script setup>
import Button from '@/Components/Button/Button.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { computed, inject, ref } from 'vue';

// Components
import Title from '@/Components/Title/Title.vue';

// Partials
import ModalUpdateHours from './Partials/ModalUpdateHours.vue';
import RetractShiftForm from './Partials/RetractShiftForm.vue';
import Shift from './Partials/Shift.vue';

const showHoursForm = ref(false);

const props = defineProps({
    shift: Object,
    shiftDetails: Object,
    liked_flexer_ids: Array,
});

const dayjs = inject('dayjs');
const shiftDate = dayjs(props.shift.start).format('DD MMMM YYYY');
const subTitle =
    shiftDate + ' | ' + dayjs(props.shift.start).format('HH:mm') + ' - ' + dayjs(props.shift.end).format('HH:mm') + ' | ' + props.shift.break / 60 + 'min';

const showRetractShiftForm = ref(false);
// const openOrFilled = computed(() => props.shift.status == 'open' || props.shift.status == 'filled');
const allowedToRetract = computed(() => props.shift.status !== 'retracted');
const allowedToEditHours = computed(() => props.shift.status !== 'open');

const openHoursForm = () => {
    showHoursForm.value = true;
};
</script>

<template>
    <StaffLayout :title="$t('Shifts')">
        <Title :title="shift.position?.name + ', ' + shift.location?.nickname" :sub-title="subTitle">
            <Button :disabled="!allowedToEditHours" orange @click="openHoursForm()">
                {{ $t('Edit Hours') }}
            </Button>
            <Button danger :disabled="!allowedToRetract" @click="showRetractShiftForm = true">
                {{ $t('Retract {model}', { model: $t('shift') }) }}
            </Button>
        </Title>

        <div class="flex gap-8">
            <div class="w-1/4">
                <div v-if="shift.description" class="mb-6 text-blue">
                    <h3 class="mb-2 text-base font-bold">{{ $t('About the shift') }}</h3>
                    <p>{{ shift.description }}</p>
                </div>

                <div v-for="(details, title) in shiftDetails" :key="title" class="mb-6 text-blue">
                    <h3 class="mb-2 text-base font-bold">{{ title }}</h3>
                    <p v-html="details"></p>
                </div>
            </div>
            <div class="w-3/4">
                <h2 class="mb-4 text-2xl font-bold text-blue">
                    {{ shiftDate }}
                </h2>
                <div class="grid">
                    <Shift :shift="shift" :dropdownOpen="true" :liked_flexer_ids="liked_flexer_ids" />
                </div>
            </div>
        </div>
    </StaffLayout>
    <RetractShiftForm v-model:showing="showRetractShiftForm" :shift="shift" />
    <ModalUpdateHours v-model:showing="showHoursForm" :shift="shift" :hideNextButton="true" />
</template>
